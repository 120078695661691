// src/components/Canvas.js
import React, { useState, useEffect } from 'react';
import { Stage, Layer, Text, Rect, Image } from 'react-konva';
import html2pdf from 'html2pdf.js';

const Canvas = () => {
    const [texts, setTexts] = useState([]);
    const [selectedTextId, setSelectedTextId] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [newText, setNewText] = useState('');
    const [fontSize, setFontSize] = useState(20); // Default font size
    const [textColor, setTextColor] = useState('#000000'); // Default text color

    useEffect(() => {
        const img = new window.Image();
        img.src = './coptic_new_cert.jpg';
        img.onload = () => {
            setBackgroundImage(img);
        };
    }, []);

    const handleTextClick = (id) => {
        setSelectedTextId(id);
    };

    const handleTextChange = (id, updatedText) => {
        setTexts((prevTexts) =>
            prevTexts.map((text) => (text.id === id ? { ...text, text: updatedText } : text))
        );
    };

    const handleTextResize = (id, newSize) => {
        setTexts((prevTexts) =>
            prevTexts.map((text) => (text.id === id ? { ...text, fontSize: newSize } : text))
        );
    };

    const handleTextColorChange = (id, newColor) => {
        setTexts((prevTexts) =>
            prevTexts.map((text) => (text.id === id ? { ...text, textColor: newColor } : text))
        );
    };

    const handleTextDragEnd = (id, e) => {
        const { x, y } = e.target.attrs;
        setTexts((prevTexts) =>
            prevTexts.map((text) => (text.id === id ? { ...text, x, y } : text))
        );
    };

    const handleAddText = () => {
        setTexts((prevTexts) => [
            ...prevTexts,
            {
                id: Date.now(),
                text: newText,
                x: 100, // Set default X position
                y: 100, // Set default Y position
                fontSize: fontSize,
                textColor: textColor,
            },
        ]);
        setNewText('');
    };

    const handleExportPDF = () => {
        const element = document.getElementById('canvas-container');
        html2pdf(element);
    };

    const selectedText = texts.find((text) => text.id === selectedTextId);

    return (
        <div>
            <div>
                <label>Add Text: </label>
                <input
                    type="text"
                    value={newText}
                    onChange={(e) => setNewText(e.target.value)}
                />
                <label>Font Size: </label>
                <input
                    type="number"
                    value={fontSize}
                    onChange={(e) => setFontSize(Number(e.target.value))}
                />
                <label>Text Color: </label>
                <input
                    type="color"
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                />
                <button onClick={handleAddText}>Add</button>
            </div>
            <div>
                {selectedText && (
                    <div>
                        <label>Selected Text Size: </label>
                        <input
                            type="number"
                            value={selectedText.fontSize}
                            onChange={(e) => handleTextResize(selectedText.id, Number(e.target.value))}
                        />
                        <label>Selected Text Color: </label>
                        <input
                            type="color"
                            value={selectedText.textColor}
                            onChange={(e) => handleTextColorChange(selectedText.id, e.target.value)}
                        />
                    </div>
                )}
            </div>
            <Stage width={1200} height={900}>
                <Layer>
                    {backgroundImage && (
                        <Image image={backgroundImage} width={1200} height={900} />
                    )}
                    <Rect
                        width={1200}
                        height={900}
                        fill="#fff"
                        opacity={0}
                        onClick={() => setSelectedTextId(null)}
                    />
                    {texts.map((text) => (
                        <Text
                            key={text.id}
                            text={text.text}
                            x={text.x}
                            y={text.y}
                            fontSize={text.fontSize}
                            fill={text.textColor}
                            draggable
                            onClick={() => handleTextClick(text.id)}
                            onDragEnd={(e) => handleTextDragEnd(text.id, e)}
                            onTransform={(e) => {
                                // Handle text resizing
                                const node = e.target;
                                const scaleX = node.scaleX();
                                const scaleY = node.scaleY();

                                node.scaleX(1);
                                node.scaleY(1);

                                const newSize = Math.max(5, node.width() * scaleX);
                                handleTextResize(text.id, newSize);

                                // Reset the scale properties to 1
                                node.scaleX(1);
                                node.scaleY(1);
                            }}
                        />
                    ))}
                </Layer>
            </Stage>
            <div>
                <button onClick={handleExportPDF}>Export PDF</button>
            </div>
        </div>
    );
};

export default Canvas;
